<template>
  <div class="addCollectionsView">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <div class="van-divider van-divider--content-center" style="text-align: center;">{{ $t('您绑定银行卡的开户名必须与您认证的实名一致，否则将无法成功提现。') }}</div>
    <div class="content_box text-center">
      <van-cell-group>
        <van-field v-model="realname" :label="$t('姓名')" readonly="" :placeholder="$t('请输入姓名')" />
      </van-cell-group>
       <van-cell-group>
        <van-field v-model="realname2" :label="$t('片假名')" :placeholder="$t('请输入片假名')" />
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="bank_name"  :label="$t('银行名称')"   :placeholder="$t('请输入银行名称')"/>
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="account" :label="$t('银行卡号')" :placeholder="$t('请输入银行卡号')" />
      </van-cell-group>
      <!-- <van-cell-group v-if="!id">
        <van-field v-model="bank_name" readonly :label="$t('银行名称')" :placeholder="$t('--请选择开户银行--')" @click="showPopup" right-icon="arrow-down" />
      </van-cell-group> -->
      <van-cell-group>
        <van-field v-model="sub_bank_name" :label="$t('分行名称')" :placeholder="$t('请输入分行名称')" />
      </van-cell-group>
       <van-cell-group>
        <van-field v-model="sub_bank_name2" :label="$t('分行编号')" :placeholder="$t('请输入分行编号')" />
      </van-cell-group>
    </div>
    <div class="btn" v-if="!id">
      <van-button color="linear-gradient(90deg,#4b34c3,#3d3ff7)" @click="submitHandler" block>{{ $t('立即设置') }}</van-button>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <van-picker show-toolbar :title="$t('银行类型')" :columns="columns" @cancel="onCancel" @confirm="onConfirm" :confirm-button-text="$t('确认')" :cancel-button-text="$t('取消')" />
    </van-popup>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from '@/utils/local-storage';
import { bankLists, addUserBank, collectionsDetail } from '@/api/collections';

export default {
  name: 'login',
  data() {
    return {
      id: this.$route.query.id,
      realname: this.$store.getters.userinfo.realname,
      account: '',
      bank_name: '',
      show: false,
      columns: [],
      mobile: this.$store.getters.userinfo.mobile,
      detail: {},
      title: '',
      sub_bank_name: '',
      sub_bank_name2:'',
      realname2:''
    };
  },
  created() {
    this.GetBankLists();
    if (this.id) {
      this.title = this.$t('银行卡详情');
      this.getCollectionsDetail();
    } else {
      this.title = this.$t('绑定银行卡');
    }
  },
  methods: {
    GetBankLists() {
      bankLists({})
        .then((response) => {
          this.columns = response.result;
        })
        .catch((error) => {});
    },
    onConfirm(value, index) {
      this.bank_name = value;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      let field;
      const { bank_name, realname, account, sub_bank_name, realname2, sub_bank_name2 } = this;
      field = {
        realname2,
        bank_name,
        realname,
        account,
        sub_bank_name,
        sub_bank_name2
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some((key) => {
        let validInfo = this.$validate['valid' + key.charAt(0).toUpperCase() + key.slice(1)](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 500,
            position: 'bottom',
          });
          return true;
        } else {
          return false;
        }
      });
      if (!objSome) {
        field.id = this.id;
        addUserBank(field)
          .then((response) => {
            this.$toast({
              message: response.msg,
              duration: 500,
              forbidClick: true,
              position: 'bottom',
            });
            setTimeout(() => {
              this.$router.replace('/main/user/collections');
            }, 500);
          })
          .catch((error) => {});
      }
    }, //获取详情
    getCollectionsDetail() {
      collectionsDetail({ id: this.id })
        .then((response) => {
          this.realname = response.result.realname;
          this.account = response.result.account;
          this.bank_name = response.result.bank_name;
          this.sub_bank_name = response.result.sub_bank_name;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.addCollectionsView
  .content_box
    text-align: left
    .van-cell-group
      margin-bottom: 0.05rem
      background: $cellBackColor
      padding: 0.1rem 0.4rem
      .van-cell
        padding: 0.15rem 0.3rem
    .van-title
      padding: 0.3rem 0 !important
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    .van-icon::before
      color: $grayTextColor
  .btn
    padding: 0.4rem 0.5rem 0
    .van-button--normal
      font-size: 0.4233rem
</style>
